<template class="page-wrapper">
  <div class="container">
  
    <!-- Upper Logo -->
    <div class="logo-container">
      <img class="logo" src="../assets/logo.png" alt="Voice Over Logo">
      <div class="intro-name">
        <h1>Salena Metreger</h1>
        <p>Voice Over Artist</p>   
      </div>
    </div>

    <!-- Main Content -->
    <div class="main-container">
      
      <!-- Contact And Demo Section -->
      <div class="intro-container">
        <!-- Headshot -->
        <div class="contact-headshot">
          <img class="head-shot" src="../assets/headshot.jpg" alt="Salena's Head-shot photo">
          
          <!-- Contact Section -->
          <div class="contact-details">
            <h2>Contact Me:</h2>
            <!-- Email -->
            <a class="contact-email" href="mailto:salenamvo@gmail.com">
              <img class="email-logo" src="../assets/email.png" alt="Voice Over Logo">
              <p>salenamvo@gmail.com</p>
            </a>
            
            <!-- Phone -->
            <a class="contact-phone" href="tel:442-500-4421" >
              <img class="phone-logo" src="../assets/phone-2.png" alt="Voice Over Logo">
              <p>442-500-4421</p>
            </a>
          </div>
        </div>
        
        <!-- Voiceover Section -->
        <div class="voiceover-clip-container">       
          <h2>My Demos</h2>

          <!-- First Clip 1 -->
          <div class="voiceover-clip">
            <p>Commercial</p>
            <audio controls class="audio-sample">
              <source src='../audio/com_demo_1.mp3' type="audio/mpeg">
              Your browser does not support the audio element.
            </audio>
          </div>
          
          <!-- Second Clip 1 -->
          <div class="voiceover-clip">
            <p>Commercial</p>
            <audio controls class="audio-sample">
              <source src='../audio/com_demo_2.mp3' type="audio/mpeg">
            </audio>
          </div>
          
          <!-- Third Clip 1 -->
          <div class="voiceover-clip">
            <p>Narration</p>
            <audio controls class="audio-sample">
              <source src='../audio/nar_demo_1.mp3' type="audio/mpeg">
            </audio>
          </div>
          
          <!-- Fourth Clip 1 -->
          <div class="voiceover-clip">
            <p>Animation</p>
            <audio controls class="audio-sample">
              <source src='../audio/anim_demo.mp3' type="audio/mpeg">
            </audio>
          </div>

        </div>
      </div>

      <!-- Main Photo -->
      <div class="hero-container">
        <div class="link">
          <div class="portrait-container">
            <img class="portrait" src="../assets/studio-shot.jpg" alt="Self portrait in studio">
          </div>

          <p>Work with me and let my voices bring some extra life to your projects! Let's go!</p>
        </div>
      </div>

      <!-- Biography section -->
      <div class="bio-container">
        <div class="about-me">
          <h1>About the Artist</h1>
          <p>Salena Metreger is not just a voice over artist; she's the vibrant, versatile voice your project needs to soar. With a background in marketing and a strong business acumen, Salena approaches each project as a canvas for your brand's narrative. Her commitment to professionalism is matched only by her dedication to infusing every script with life, energy, and personality.
          <br>
          <br>
          Salena's voice type is as diverse as her skill set. From warm and inviting to authoritative and commanding, she has the versatility to bring any character or concept to life. Whether you need a relatable spokesperson, a reassuring narrator, or a quirky animated character, Salena's voice is the secret ingredient that adds depth and dimension to your project. Her dynamic range allows her to seamlessly transition from corporate narration to quirky character roles, ensuring that each delivery is tailored to resonate with your target audience. Whether it's a commercial, e-learning module, audiobook, or video game.</p>
        </div>
        
        <!-- Equipment -->
        <div class="equipment">
          <h1>Equipment</h1>
          <ul>
            <li>Sound Treated In-Home Studio</li>
            <li>Audio-Technica AT2020 Cardioid Condenser Studio XLR Microphone with Pop Filter</li>
            <li>Beyerdynamic DT 770 PRO Headphones</li>
            <li>Focusrite Scarlett Solo 3rd Gen USB Audio Interface</li>
            <li>Soundforge Audio Studio</li>
            <li>Thinkpad Custom Built Laptop</li>
          </ul>
        </div>
        
        <!-- Samples -->
        <div class="recents">
          <h1>Samples</h1>
          
          <div class="sample-container">
            <div class="voiceover-container">
              <!-- Clip 1 -->
              <div class="voiceover-clip">
                <p>Commercial Sample</p>
                <audio controls class="audio-sample">
                  <source src='../audio/bhbc_sample.wav' type="audio/mpeg">
                </audio>
              </div>
              
              <!-- Clip 2 -->
              <div class="voiceover-clip">
                <p>Character Sample</p>
                <audio controls class="audio-sample">
                  <source src='../audio/shanti_sample.mp3' type="audio/mpeg">
                </audio>
              </div>
              
              <!-- Clip 3 -->
              <div class="voiceover-clip">
                <p>Explainer Sample</p>
                <audio controls class="audio-sample">
                  <source src='../audio/weiser_sample.mp3' type="audio/mpeg">
                </audio>
              </div>
            </div>

            <!-- Video/Audio Clip -->
            <div class="video-container">
              <div class="video-clip">
                <video width="560" height="315" controls>
                  <source src="../audio/BHBC-7-year-anniv.mp4" type="video/mp4">
                </video>
              </div>
            </div>
          </div>
        </div>
        
        <!-- Testimonials -->
        <div class="testimonials">
          <h1>Testimonials</h1>
          
          <!-- Testimonial 1 -->
          <p class="testimonial">
            "She is a very gifted actress with unique vocal qualities. I would have no hesitation recommending Salena to any project. She would be an asset to any production she was cast in." 
          </p>
          <p class="attribute">- Bunny - Del Mar Media Arts</p>
          
          <!-- Testimonial 2 -->
          <p class="testimonial">
            "Salena is so cool. I love to see physication and visual acting. It is so important. I will certainly remember Ms. Salena." 
          </p>
          <p class="attribute">- Jonathan M J - Performance Production</p>
          
          <!-- Testimonial 3 -->
          <p class="testimonial">
            "Took direction well. A bright, young, even child-like voice. I hear her having a future in animation." 
          </p>
          <p class="attribute">- Mike S - Beard Boy Productions</p>
        </div>
      </div>

      <!-- Bodalgo Badge -->
      <div class="logo-badge">
        <a href="https://www.bodalgo.com/en/voice-over-talents/salena-metreger?referer=badge" rel="noopener" target="_blank"><img alt="Professional Voice Talents" title="Professional Voice Talents" width="200" src="https://www.bodalgo.com/voice-over-talents/badge/salena-metreger/3/200"></a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      
    }
  }
}
</script>

<style lang="scss">
  h1 {
    font-size: 34px;
  }

  h2 {
    font-size: 28px;
  }

  p {
    font-size: 20px;
  }

  .portrait-container {
    @media (max-width: 1200px) {
      flex-direction: column;
    }
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 20px 0;
    
    .portrait {
      height: 100%;
      max-width: 600px;
      width: 80vw;
      border-radius: 20%;
    }
  }

  .logo {
    height: 75px;
  }

  .email-logo {
    height: 36px;
    margin-right: 16px;
  }

  .phone-logo {
    height: 45px;
    margin-left: -5px;
    margin-right: 13px;
  }

  .intro-name {
    h1 {
      text-transform: uppercase;
    }  
  }

  .container {
    @media (max-width: 1000px) {
      margin: 10px 40px;
    }
    margin: 10px 200px;
  }

  .hero-container {
    text-align: center;
    padding-bottom: 50px;
    p {
      padding-top: 20px;
    }
  }

  .bio-container {
    text-align: left;
    padding-bottom: 100px;
    ul {
      padding-inline-start: 20px;
    }
  }

  .about-me,
  .equipment,
  .recents,
  .testimonials {
    @media (max-width: 800px) {
      padding-bottom: 30px;
    }
    
    padding-bottom: 50px;
  }

  .equipment {
    li {
      margin-left: 20px;
      padding-left: 10px;
      font-size: 18px;
    }
  }

  .testimonial {
    font-weight: bold;
    font-size: 16px;
    width: 60%;
    
    @media (max-width: 800px) {
      width: 80%;
    }
  }

  .attribute {
    font-size: 14px;
    margin-left: 30px;
    font-style: italic;
    padding-bottom: 20px;
    width: 60%;

    @media (max-width: 600px) {
      width: 80%;
    }
  }

  .logo-container {
    @media (max-width: 600px) {
      display: block;
    }
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid grey;
  }

  .intro-container {
    @media (max-width: 1340px) {
      flex-direction: column;
      justify-self: center;
    }

    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    padding-bottom: 50px;
    justify-content: space-between;
  }

  .contact-headshot,
  .contact-details,
  .voiceover-clip-container {
    @media (max-width: 1000px) {
      padding-bottom: 50px;
    }
    padding-bottom: 0;
  }

  .contact-headshot {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  
    .head-shot {
      max-width: 330px;
      max-height: 300px;
      margin: 10px 35px 0 0px;
      border-radius: 20%;

      @media (max-width: 1000px) {
        margin: 50px 20px 50px 0;
      }
    }

    .contact-details {
      text-align: left;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .contact-email, 
    .contact-phone {
      display: flex;
      align-items: center;
      text-decoration: none;
      color: lightskyblue;
      font-size: 26px;
      justify-content: left;
    }
  }

  .voiceover-clip-container {
    @media (max-width: 600px) {
      display: block;
    }
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .voiceover-clip {
    @media (max-width: 600px) {
      display: block;
    }
    max-width: 500px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .audio-sample {
    padding: 10px 20px;
    color: white;
  }

  .sample-container {
    display: flex;
    flex-direction: column;

    .voiceover-container {
      padding-bottom: 50px;
      
      @media (min-width: 1400px) {
        padding-bottom: 0;
      }
    }

    @media (min-width: 1400px) {
      flex-direction: row;
      justify-content: space-between;
    }
  }

</style>